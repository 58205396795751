
.green{
  background: #409b12;
}

.orange{
    background: #f17c57fa;
}

.blue{
  background: #3652D9;
}

.rose{
  background:#FF66CC;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.master{
  color:black;
  float:right;
}

@media (max-width:900px){
  .master{
    display:none
  }
}